import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'qa',

  featureFlags: {
    ...base.featureFlags,
  },
  ecomm: {
    ...base.ecomm
  },
  sentry: {
    ...base.sentry,
    enableSentry: true,
    enableSentryFeedbackDialog: true,
    dsn: 'https://4cf4a6e15fc97735ef63112e5604c4eb@o4506547460440064.ingest.sentry.io/4506559211438080',
  },
};
